.#{$prefix}-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 #{-$form-space};
  > * {
    width: 100%;
    margin: 0 $form-space;
  }
  > p {
    margin-top: rem(37px);
    text-align: center;
  }
  .#{$prefix}-input {
    margin: 0 $form-space rem(25px);
    &.w-50 {
      width: calc(50% - #{$form-space * 2});
      @include respond-to(xs) {
        width: 100%;
      }
    }
    + hr {
      margin-top: rem(14px);
    }
  }
  hr {
    margin: rem(34px) $form-space rem(36px);
  }
  &-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .#{$prefix}-input {
      &.w-50 {
        width: calc(50% - #{$form-space});
        margin: 0 0 rem(25px);
        @include respond-to(xs) {
          width: 100%;
        }
      }
    }
  }
}

.#{$prefix}-input {
  display: block;
  width: 100%;
  input {
    box-shadow: 0 rem(2px) rem(4px) 0 rgba($black, 0.06);
    &:focus {
      color: rgba($black, 0.8);
    }
  }
  &-label {
    display: block;
    line-height: 1.5;
    color: $gray-400;
    margin-bottom: rem(5px);
  }
  &-validation-error, &-info {
    padding: 0;
    color: $red-500 !important;
    font-size: rem(14px);
    line-height: rem(22px); 
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  &-info {
    color: $gray-400 !important;
  }
}

textarea {
  width: 100%;
  position: relative;
  font-family: $font-family-base;
  font-size: rem(16px);
  line-height: 1.13;
  margin: 0;
  padding: rem(20px) rem(12px);
  box-shadow: 0 rem(2px) rem(4px) 0 rgba($black, 0.06);
  border: solid rem(1px) $gray-300;
  border-radius: rem(4px);
  resize: none;
  outline: none;
  outline-width: 0;
  &[value=''] {
    color: $gray-400;
  }
  &:focus {
    color: rgba($black, 0.8);
  }
}