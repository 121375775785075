// Calculate to Rem
@function rem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

// Responsive
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else if $breakpoint == 'ie' {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}