@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


.slick-nav-slider {
  .slick {
    &-prev,
    &-next {
      &::before {
        content: none;
      }
    }
    &-slider:last-of-type {
      .slick-list {
        margin: rem(-10px) rem(55px) 0;
        padding-bottom: rem(10px) !important;
        padding-top: rem(10px) !important;
        @include respond-to(md) {
          margin: 0 rem(45px);
        }
      }
      .slick-prev,
      .slick-next {
        width: rem(38px);
        height: rem(38px);
      }
      .slick-prev {
        left: rem(-4px);
      }
      .slick-next {
        right: rem(-4px);
      }
      .slick-slide {
        position: relative;
        padding: 0 rem(10px);
        @include respond-to(md) {
          padding: 0 rem(5px);
        }
        &.slick-current::after {
          content: '';
          width: calc(100% - 20px);
          height: 105%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: rgba($white ,.2);
          border-bottom: rem(2px) solid $gray-300;
          margin: auto;
          @include respond-to(md) {
            width: calc(100% - 10px);
          }
        }
      }
    }
  }
  .#{$prefix}-image-caption {
    @include respond-to(md) {
      display: none;
    }
  }
}