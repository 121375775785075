@import url(~react-perfect-scrollbar/dist/css/styles.css);
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("../../fonts/roboto/Roboto-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("../../fonts/roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("../../fonts/roboto/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

h1, h2, h3, h4, h5 {
  margin: 0 0 1.875rem;
  color: #1f1c17; }

h1, h2 {
  font-size: 2.75rem;
  line-height: 1.23; }
  @media (max-width: 767px) {
    h1, h2 {
      font-size: 2rem;
      line-height: 1.13; } }

h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.31; }
  @media (max-width: 767px) {
    h3 {
      font-size: 1.5rem;
      line-height: 1.25; } }

h4 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.38; }
  @media (max-width: 767px) {
    h4 {
      font-size: 1.125rem;
      line-height: 1.33; } }

h5 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.09375rem;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    h5 {
      font-size: 0.875rem;
      line-height: 1.31; } }

a,
a.eleo-link,
button.eleo-link {
  cursor: pointer;
  background: transparent;
  border: 0;
  transition: opacity ease 300ms, color ease 300ms;
  font-size: 1.1875rem;
  font-weight: 500;
  line-height: 1.26;
  color: #566e3a;
  text-decoration: none; }
  @media (max-width: 767px) {
    a,
    a.eleo-link,
    button.eleo-link {
      font-size: 1rem;
      line-height: 1.25; } }
  a:focus, a:hover,
  a.eleo-link:focus,
  a.eleo-link:hover,
  button.eleo-link:focus,
  button.eleo-link:hover {
    text-decoration: underline;
    opacity: 0.9; }
  a.eleo-link-sm,
  a.eleo-link.eleo-link-sm,
  button.eleo-link.eleo-link-sm {
    font-size: 1rem;
    line-height: 1.25; }
    a.eleo-link-sm.eleo-link-icon img,
    a.eleo-link.eleo-link-sm.eleo-link-icon img,
    button.eleo-link.eleo-link-sm.eleo-link-icon img {
      max-width: 0.6875rem; }
    a.eleo-link-sm.eleo-link-icon-lg,
    a.eleo-link.eleo-link-sm.eleo-link-icon-lg,
    button.eleo-link.eleo-link-sm.eleo-link-icon-lg {
      display: inline-flex;
      align-items: center; }
      a.eleo-link-sm.eleo-link-icon-lg img,
      a.eleo-link.eleo-link-sm.eleo-link-icon-lg img,
      button.eleo-link.eleo-link-sm.eleo-link-icon-lg img {
        max-width: 1.5rem;
        margin-right: 0.6875rem; }
  a.eleo-link-xs,
  a.eleo-link.eleo-link-xs,
  button.eleo-link.eleo-link-xs {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline; }
    a.eleo-link-xs.eleo-link-icon img,
    a.eleo-link.eleo-link-xs.eleo-link-icon img,
    button.eleo-link.eleo-link-xs.eleo-link-icon img {
      max-width: 0.6875rem; }
  a.eleo-link-xxs,
  a.eleo-link.eleo-link-xxs,
  button.eleo-link.eleo-link-xxs {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline; }
    a.eleo-link-xxs.eleo-link-icon img,
    a.eleo-link.eleo-link-xxs.eleo-link-icon img,
    button.eleo-link.eleo-link-xxs.eleo-link-icon img {
      max-width: 0.6875rem; }
  a.eleo-link-secondary,
  a.eleo-link.eleo-link-secondary,
  button.eleo-link.eleo-link-secondary {
    color: #333333;
    text-decoration: underline; }
  a.eleo-link-secondary-light,
  a.eleo-link.eleo-link-secondary-light,
  button.eleo-link.eleo-link-secondary-light {
    color: #888888;
    text-decoration: underline; }
  a.eleo-link-icon,
  a.eleo-link.eleo-link-icon,
  button.eleo-link.eleo-link-icon {
    display: inline-flex;
    align-items: center; }
    a.eleo-link-icon img,
    a.eleo-link.eleo-link-icon img,
    button.eleo-link.eleo-link-icon img {
      position: relative;
      top: -0.0625rem;
      margin-left: 0.4375rem;
      max-width: 0.875rem; }
  a .eleo-icon-rotate,
  a.eleo-link .eleo-icon-rotate,
  button.eleo-link .eleo-icon-rotate {
    transform: rotate(45deg); }
  a.eleo-link-light-gray,
  a.eleo-link.eleo-link-light-gray,
  button.eleo-link.eleo-link-light-gray {
    color: #888888; }
  a-checkbox,
  a.eleo-link-checkbox,
  button.eleo-link-checkbox {
    display: inline;
    margin: 0;
    padding: 0;
    font-weight: normal;
    text-decoration: underline; }

p,
a.eleo-link,
.eleo-text {
  font-size: 1.1875rem;
  line-height: 1.58;
  color: #4c4c4c;
  margin: 1.875rem 0; }
  @media (max-width: 767px) {
    p,
    a.eleo-link,
    .eleo-text {
      font-size: 1rem;
      line-height: 1.5; } }
  p.eleo-text-xs,
  a.eleo-link.eleo-text-xs,
  .eleo-text.eleo-text-xs {
    font-size: 0.875rem; }
  p.eleo-text-sm,
  a.eleo-link.eleo-text-sm,
  .eleo-text.eleo-text-sm {
    font-size: 1rem;
    line-height: 1.5;
    color: #333333; }
  p.eleo-text-gray,
  a.eleo-link.eleo-text-gray,
  .eleo-text.eleo-text-gray {
    color: #888888; }
  p.eleo-text-label,
  a.eleo-link.eleo-text-label,
  .eleo-text.eleo-text-label {
    font-size: 14px;
    line-height: 1.57;
    color: #888888;
    margin-bottom: 1.3125rem; }
    @media (max-width: 767px) {
      p.eleo-text-label,
      a.eleo-link.eleo-text-label,
      .eleo-text.eleo-text-label {
        font-size: 0.8125rem; } }
  p.eleo-image-caption,
  a.eleo-link.eleo-image-caption,
  .eleo-text.eleo-image-caption {
    margin-top: 0.875rem;
    text-align: center; }
  p-through,
  a.eleo-link-through,
  .eleo-text-through {
    text-decoration: line-through; }

.ReactModal__Body--open {
  overflow: hidden; }

.ReactModalPortal .eleo-modal {
  position: absolute;
  top: 2.5rem;
  left: 17.5%;
  right: 17.5%;
  bottom: 2.5rem;
  box-shadow: 0 2.5rem 3.8125rem 0 rgba(31, 28, 23, 0.12);
  background: #ffffff;
  overflow: hidden;
  outline: none;
  border-radius: 0.8125rem;
  padding: 1.5rem 0 0 0; }
  @media (max-width: 767px) {
    .ReactModalPortal .eleo-modal {
      top: 1.25rem;
      bottom: 1.25rem;
      left: 4%;
      right: 4%; } }
  .ReactModalPortal .eleo-modal-small {
    bottom: auto; }

.ReactModalPortal .eleo-modal-image-preview {
  top: 3vh;
  bottom: 3vh;
  left: 17.5%;
  right: 17.5%;
  padding: 0; }
  @media (max-width: 1239px) {
    .ReactModalPortal .eleo-modal-image-preview {
      right: 3vh;
      left: 3vh; } }
  .ReactModalPortal .eleo-modal-image-preview .eleo-modal-header {
    height: 50px;
    display: flex;
    position: absolute;
    padding-top: 1.5rem;
    top: 0;
    right: 0;
    margin: 0; }
  .ReactModalPortal .eleo-modal-image-preview .eleo-modal-content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vh 3vw; }
    .ReactModalPortal .eleo-modal-image-preview .eleo-modal-content img {
      max-width: 100%;
      max-height: 88vh; }
    @media (max-width: 1239px) {
      .ReactModalPortal .eleo-modal-image-preview .eleo-modal-content img {
        max-width: 88vw; } }

.ReactModalPortal .eleo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(31, 28, 23, 0.85); }

.ps__rail-y {
  left: auto !important;
  right: 0; }

.ps__thumb-y {
  right: 1px; }

.slick-nav-slider .slick-prev::before, .slick-nav-slider .slick-next::before {
  content: none; }

.slick-nav-slider .slick-slider:last-of-type .slick-list {
  margin: -0.625rem 3.4375rem 0;
  padding-bottom: 0.625rem !important;
  padding-top: 0.625rem !important; }
  @media (max-width: 991px) {
    .slick-nav-slider .slick-slider:last-of-type .slick-list {
      margin: 0 2.8125rem; } }

.slick-nav-slider .slick-slider:last-of-type .slick-prev,
.slick-nav-slider .slick-slider:last-of-type .slick-next {
  width: 2.375rem;
  height: 2.375rem; }

.slick-nav-slider .slick-slider:last-of-type .slick-prev {
  left: -0.25rem; }

.slick-nav-slider .slick-slider:last-of-type .slick-next {
  right: -0.25rem; }

.slick-nav-slider .slick-slider:last-of-type .slick-slide {
  position: relative;
  padding: 0 0.625rem; }
  @media (max-width: 991px) {
    .slick-nav-slider .slick-slider:last-of-type .slick-slide {
      padding: 0 0.3125rem; } }
  .slick-nav-slider .slick-slider:last-of-type .slick-slide.slick-current::after {
    content: '';
    width: calc(100% - 20px);
    height: 105%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    border-bottom: 0.125rem solid #c6bdaa;
    margin: auto; }
    @media (max-width: 991px) {
      .slick-nav-slider .slick-slider:last-of-type .slick-slide.slick-current::after {
        width: calc(100% - 10px); } }

@media (max-width: 991px) {
  .slick-nav-slider .eleo-image-caption {
    display: none; } }

.eleo-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -1.125rem; }
  .eleo-form > * {
    width: 100%;
    margin: 0 1.125rem; }
  .eleo-form > p {
    margin-top: 2.3125rem;
    text-align: center; }
  .eleo-form .eleo-input {
    margin: 0 1.125rem 1.5625rem; }
    .eleo-form .eleo-input.w-50 {
      width: calc(50% - 2.25rem); }
      @media (max-width: 575px) {
        .eleo-form .eleo-input.w-50 {
          width: 100%; } }
    .eleo-form .eleo-input + hr {
      margin-top: 0.875rem; }
  .eleo-form hr {
    margin: 2.125rem 1.125rem 2.25rem; }
  .eleo-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .eleo-form-row .eleo-input.w-50 {
      width: calc(50% - 1.125rem);
      margin: 0 0 1.5625rem; }
      @media (max-width: 575px) {
        .eleo-form-row .eleo-input.w-50 {
          width: 100%; } }

.eleo-input {
  display: block;
  width: 100%; }
  .eleo-input input {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 28, 23, 0.06); }
    .eleo-input input:focus {
      color: rgba(31, 28, 23, 0.8); }
  .eleo-input-label {
    display: block;
    line-height: 1.5;
    color: #888888;
    margin-bottom: 0.3125rem; }
  .eleo-input-validation-error, .eleo-input-info {
    padding: 0;
    color: #e54b4b !important;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal; }
  .eleo-input-info {
    color: #888888 !important; }

textarea {
  width: 100%;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.13;
  margin: 0;
  padding: 1.25rem 0.75rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 28, 23, 0.06);
  border: solid 0.0625rem #c6bdaa;
  border-radius: 0.25rem;
  resize: none;
  outline: none;
  outline-width: 0; }
  textarea[value=''] {
    color: #888888; }
  textarea:focus {
    color: rgba(31, 28, 23, 0.8); }

* {
  box-sizing: border-box;
  outline: none; }

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff; }

button {
  font-family: "Roboto", sans-serif; }

.eleo-last-step .eleo-layout-color {
  padding-bottom: 0; }
  .eleo-last-step .eleo-layout-color-part {
    height: calc(100vh - 5.3125rem); }
    @media (max-width: 991px) {
      .eleo-last-step .eleo-layout-color-part {
        height: 100%; } }

[data-test-id*="ChatWidget"] {
  display: none !important; }

.eleo-container {
  position: relative;
  width: 100%;
  max-width: 106.25rem;
  margin: 0 auto;
  padding: 0; }
  .eleo-container::before, .eleo-container::after {
    content: none; }
  .eleo-container.eleo-container-lg, .eleo-container.eleo-container-md, .eleo-container.eleo-container-sm {
    padding: 0 2.625rem; }
    @media (max-width: 767px) {
      .eleo-container.eleo-container-lg, .eleo-container.eleo-container-md, .eleo-container.eleo-container-sm {
        padding: 0 1.125rem; } }
  .eleo-container.eleo-container-lg {
    max-width: 85.25rem; }
  .eleo-container.eleo-container-md {
    max-width: 74.625rem; }
  .eleo-container.eleo-container-sm {
    max-width: 51.5rem; }
  .eleo-container.eleo-container-example {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.875rem;
    min-height: 6.25rem;
    background: #9eb781; }
    .eleo-container.eleo-container-example::before, .eleo-container.eleo-container-example::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      background: #566e3a;
      width: 2.625rem; }
      @media (max-width: 767px) {
        .eleo-container.eleo-container-example::before, .eleo-container.eleo-container-example::after {
          width: 1.125rem; } }
    .eleo-container.eleo-container-example::before {
      left: 0; }
    .eleo-container.eleo-container-example::after {
      right: 0; }
  .eleo-container.no-padding::before, .eleo-container.no-padding::after {
    content: none; }

.eleo-row {
  display: flex; }
  @media (max-width: 767px) {
    .eleo-row {
      flex-wrap: wrap; } }

.eleo-layout-wrapper {
  max-width: 33.9375rem; }
  @media (max-width: 991px) {
    .eleo-layout-wrapper {
      margin: auto; } }

.eleo-layout-color {
  display: flex; }
  @media (max-width: 991px) {
    .eleo-layout-color {
      flex-wrap: wrap; } }
  @media (max-width: 767px) {
    .eleo-layout-color {
      padding-bottom: 3.75rem; } }
  .eleo-layout-color-part {
    width: 100%;
    height: calc(100vh - 5.3125rem);
    overflow: auto; }
    @media (max-width: 991px) {
      .eleo-layout-color-part {
        height: 100%; } }
    .eleo-layout-color-part-padding {
      padding: 4rem 5rem 6rem; }
      @media (max-width: 991px) {
        .eleo-layout-color-part-padding {
          padding: 4rem; } }
      @media (max-width: 767px) {
        .eleo-layout-color-part-padding {
          padding: 2rem 1.125rem; } }
      .eleo-layout-color-part-padding .eleo-layout-toggle button {
        color: #1f1c17;
        transition: opacity ease 300ms;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1.1875rem 0 1.0625rem;
        text-decoration: none; }
        .eleo-layout-color-part-padding .eleo-layout-toggle button:hover, .eleo-layout-color-part-padding .eleo-layout-toggle button:focus {
          opacity: 0.85; }
        .eleo-layout-color-part-padding .eleo-layout-toggle button img.eleo-icon {
          transition: transform ease 300ms;
          margin-right: 0;
          filter: brightness(0); }
        .eleo-layout-color-part-padding .eleo-layout-toggle button .eleo-text-gray {
          font-weight: normal; }
      @media (max-width: 767px) {
        .eleo-layout-color-part-padding .eleo-layout-toggle-content {
          display: none; } }
      .eleo-layout-color-part-padding.open .eleo-layout-toggle button img.eleo-icon {
        transform: rotate(180deg); }
      @media (max-width: 767px) {
        .eleo-layout-color-part-padding.open .eleo-layout-toggle-content {
          display: block !important; } }
    .eleo-layout-color-part:first-child {
      background-color: #eeebe5; }
      .eleo-layout-color-part:first-child .eleo-toggle {
        min-height: auto; }
        @media (max-width: 767px) {
          .eleo-layout-color-part:first-child .eleo-toggle {
            padding: 0 1.125rem; } }
    .eleo-layout-color-part:last-child {
      background-color: #f7f6f4; }
      .eleo-layout-color-part:last-child-padding {
        padding: 4rem 3.5rem 4rem 5rem; }

.w-12 {
  width: 12.5%; }

.w-25 {
  width: 25%; }

.w-33 {
  width: 33.33%; }

.w-50 {
  width: 50%; }

.w-75 {
  width: 75%; }

.w-100 {
  width: 100%; }

@media (max-width: 767px) {
  .w-12,
  .w-25,
  .w-33,
  .w-50,
  .w-75,
  .w-100 {
    width: 100%; } }

hr {
  width: 100%;
  border: 0;
  border-bottom: 0.0625rem solid #d7d7d6;
  margin: 2.125rem 0 2.25rem;
  border-color: #d7d7d6; }

.d-block {
  display: block; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: flex-start; }

.align-items-end {
  align-items: flex-end; }

.align-items-baseline {
  align-items: baseline; }

.align-items-center {
  align-items: center; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-space-between {
  justify-content: space-between; }

.justify-content-center {
  justify-content: center; }

.flex-direction-column {
  flex-direction: column; }

.flex-direction-row {
  flex-direction: row; }

@media (max-width: 767px) {
  .hide-sm {
    display: none; } }

.show-sm {
  display: none; }
  @media (max-width: 767px) {
    .show-sm {
      display: block; } }

.hidden {
  display: none; }

.eleo-input-valid {
  border: solid 0.0625rem #9eb781;
  background-color: #f1f4ed; }

.eleo-input-invalid {
  color: #e54b4b;
  border: solid 0.0625rem #e54b4b;
  background-color: #faeeee; }

.hide-input-number-arrows input::-webkit-outer-spin-button,
.hide-input-number-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.hide-input-number-arrows input[type=number] {
  -moz-appearance: textfield; }

.cursor-auto {
  cursor: auto; }

.cursor-pointer {
  cursor: pointer; }
