* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: $font-family-base;
  background-color: $white;
}

button {
  font-family: $font-family-base;
}

.#{$prefix}-last-step {
  .#{$prefix}-layout-color {
    padding-bottom: 0;
    &-part {
      height: calc(100vh - #{rem(85px)});
      @include respond-to(md) {
        height: 100%;
      }
    }
  }
}

[data-test-id*="ChatWidget"] {
  display: none !important;
}