@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

$font-family-roboto: 'Roboto', sans-serif;

$font-family-base: $font-family-roboto;

$font-family-light: 300;
$font-family-normal: 400;
$font-family-medium: 500;
$font-family-semi-bold: 600;
$font-family-bold: 700;
$font-family-black: 900;