h1, h2, h3, h4, h5 {
  margin: 0 0 rem(30px);
  color: $black;
}

h1, h2 {
  font-size: rem(44px);
  line-height: 1.23;
  @include respond-to(sm) {
    font-size: rem(32px);
    line-height: 1.13;
  }
}

h3 {
  font-size: rem(32px);
  font-weight: $font-family-medium;
  line-height: 1.31;
  @include respond-to(sm) {
    font-size: rem(24px);
    line-height: 1.25;
  }
}

h4 {
  font-size: rem(24px);
  font-weight: $font-family-medium;
  line-height: 1.38;
  @include respond-to(sm) {
    font-size: rem(18px);
    line-height: 1.33;
  }
}

h5 {
  font-size: rem(16px);
  font-weight: $font-family-medium;
  line-height: 1.5;
  letter-spacing: rem(1.5px);
  text-transform: uppercase;
  @include respond-to(sm) {
    font-size: rem(14px);
    line-height: 1.31;
  }
}

a,
a.#{$prefix}-link,
button.#{$prefix}-link {
  cursor: pointer;
  background: transparent;
  border: 0;
  transition: opacity ease 300ms, color ease 300ms;
  font-size: rem(19px);
  font-weight: $font-family-medium;
  line-height: 1.26;
  color: $green-600;
  text-decoration: none;
  @include respond-to(sm) {
    font-size: rem(16px);
    line-height: 1.25;
  }
  &:focus,
  &:hover {
    text-decoration: underline;
    opacity: 0.9;
  }
  &.#{$prefix}-link-sm {
    font-size: rem(16px);
    line-height: 1.25;
    &.#{$prefix}-link-icon {
      img {
        max-width: rem(11px);
      }
    }
    &.#{$prefix}-link-icon-lg {
      display: inline-flex;
      align-items: center;
      img {
        max-width: rem(24px);
        margin-right: rem(11px);
      }
    }
  }
  &.#{$prefix}-link-xs {
    font-size: rem(14px);
    line-height: rem(22px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline;
    &.#{$prefix}-link-icon {
      img {
        max-width: rem(11px);
      }
    }
  }
  &.#{$prefix}-link-xxs {
    font-size: rem(12px);
    line-height: rem(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline;
    &.#{$prefix}-link-icon {
      img {
        max-width: rem(11px);
      }
    }
  }
  &.#{$prefix}-link-secondary {
    color: $gray-800;
    text-decoration: underline;
  }
  &.#{$prefix}-link-secondary-light {
    color: $gray-400;
    text-decoration: underline;
  }
  &.#{$prefix}-link-icon {
    display: inline-flex;
    align-items: center;
    img {
      position: relative;
      top: rem(-1px);
      margin-left: rem(7px);
      max-width: rem(14px);
    }
  }
  .#{$prefix}-icon {
    &-rotate {
      transform: rotate(45deg);
    }
  }
  &.#{$prefix}-link-light-gray {
    color: $gray-400;
  }
  &-checkbox {
    display: inline;
    margin: 0;
    padding: 0;
    font-weight: normal;
    text-decoration: underline;
  }
}

p,
a.#{$prefix}-link,
.#{$prefix}-text {
  font-size: rem(19px);
  line-height: 1.58;
  color: $gray-600;
  margin: rem(30px) 0;
  @include respond-to(sm) {
    font-size: rem(16px);
    line-height: 1.5;
  }
  &.#{$prefix}-text-xs {
    font-size: rem(14px);
  }
  &.#{$prefix}-text-sm {
    font-size: rem(16px);
    line-height: 1.5;
    color: $gray-800;
  }
  &.#{$prefix}-text-gray {
    color: $gray-400;
  }
  &.#{$prefix}-text-label {
    font-size: 14px;
    line-height: 1.57;
    color: $gray-400;
    margin-bottom: rem(21px);
    @include respond-to(sm) {
      font-size: rem(13px);
    }
  }
  &.#{$prefix}-image-caption {
    margin-top: rem(14px);
    text-align: center;
  }
  &-through {
    text-decoration: line-through;
  }
}