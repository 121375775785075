.#{$prefix}-input {
  &-valid {
    border: solid rem(1px) $green-200;
    background-color: $green-100;
  }
  &-invalid {
    color: $red-500;
    border: solid rem(1px) $red-500;
    background-color: $red-100;
  }
}
.hide-input-number-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}