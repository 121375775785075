.ReactModal {
  &__Body--open {
    overflow: hidden;
  }
}

.ReactModalPortal {
  .#{$prefix}-modal {
    position: absolute;
    top: rem(40px);
    left: 17.5%;
    right: 17.5%;
    bottom: rem(40px);
    box-shadow: 0 rem(40px) rem(61px) 0 rgba($black, 0.12);
    background: $white;
    overflow: hidden;
    outline: none;
    border-radius: rem(13px);
    padding: rem(24px) 0 0 0;
    @include respond-to(sm) {
      top: rem(20px);
      bottom: rem(20px);
      left: 4%;
      right: 4%;
    }
    &-small {
      bottom: auto;
    }
  }
  .#{$prefix}-modal-image-preview {
    top: 3vh;
    bottom: 3vh;
    left: 17.5%;
    right: 17.5%;
    padding: 0;
    @include respond-to(lg) {
      right: 3vh;
      left: 3vh;
    }
    .eleo-modal-header {
      height: 50px;
      display: flex;
      position: absolute;
      padding-top: 1.5rem;
      top: 0;
      right: 0;
      margin: 0;
    }
    .eleo-modal-content {
      width: 100%;
      height: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3vh 3vw;
      img {
        max-width: 100%;
        max-height: 88vh;
      }
      @include respond-to(lg) {
        img {
          max-width: 88vw;
        }
      }
    }
  }
  .#{$prefix}-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba($black, 0.85);
  }
}
