.#{$prefix}-container {
  position: relative;
  width: 100%;
  max-width: rem(1700px);
  margin: 0 auto;
  padding: 0;
  &::before,
  &::after {
    content: none;
  }
  &.#{$prefix}-container-lg,
  &.#{$prefix}-container-md,
  &.#{$prefix}-container-sm {
    padding: 0 rem(42px);
    @include respond-to(sm) {
      padding: 0 rem(18px);
    }
  }
  &.#{$prefix}-container-lg {
    // 1280 + 84 padding
    max-width: rem(1364px);
  }
  &.#{$prefix}-container-md {
    // 1110 + 84 padding
    max-width: rem(1194px);
  }
  &.#{$prefix}-container-sm {
    // 740 + 84 padding
    max-width: rem(824px);
  }
  &.#{$prefix}-container-example {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(30px);
    min-height: rem(100px);
    background: $green-200;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      background: $green-600;
      width: rem(42px);
      @include respond-to(sm) {
        width: rem(18px);
      }
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  &.no-padding {
    &::before,
    &::after {
      content: none;
    }
  }
}

.#{$prefix}-row {
  display: flex;
  @include respond-to(sm) {
    flex-wrap: wrap;
  }
}

.#{$prefix}-layout {
  &-wrapper {
    max-width: rem(543px);
    @include respond-to(md) {
      margin: auto;
    }
  }
  &-color {
    display: flex;
    @include respond-to(md) {
      flex-wrap: wrap;
    }
    @include respond-to(sm) {
      padding-bottom: rem(60px);
    }
    &-part {
      width: 100%;
      height: calc(100vh - #{rem(85px)});
      overflow: auto;
      @include respond-to(md) {
        height: 100%;
      }
      &-padding {
        padding: rem(64px) rem(80px) rem(96px);
        @include respond-to(md) {
          padding: rem(64px);
        }
        @include respond-to(sm) {
          padding: rem(32px) rem(18px);
        }
        .#{$prefix}-layout-toggle {
          button {
            color: $black;
            transition: opacity ease 300ms;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: rem(19px) 0 rem(17px);
            text-decoration: none;
            &:hover,
            &:focus {
              opacity: 0.85;
            }
            img.#{$prefix}-icon {
              transition: transform ease 300ms;
              margin-right: 0;
              filter: brightness(0);
            }
            .#{$prefix}-text-gray {
              font-weight: normal;
            }
          }
        }
        .#{$prefix}-layout-toggle-content {
          @include respond-to(sm) {
            display: none;
          }
        }
        &.open {
          .#{$prefix}-layout-toggle {
            button {
              img.#{$prefix}-icon {
                transform: rotate(180deg);
              }
            }
            &-content {
              @include respond-to(sm) {
                display: block !important;
              }
             
            }
          }
        }
      }
      &:first-child {
        background-color: $gray-250;
        .#{$prefix}-toggle {
          min-height: auto;
          @include respond-to(sm) {
            padding: 0 rem(18px);
          }
        }
      }
      &:last-child {
        background-color: $gray-100;
        &-padding {
          padding: rem(64px) rem(56px) rem(64px) rem(80px);
        }
      }
    }
  }
}

.w-12 {
  width: 12.5%;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33.33%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-12,
.w-25,
.w-33,
.w-50,
.w-75,
.w-100 {
  @include respond-to(sm) {
    width: 100%;
  }
}

hr {
  width: 100%;
  border: 0;
  border-bottom: rem(1px) solid $gray-200;
  margin: rem(34px) 0 rem(36px);
  border-color: $gray-200;
}

.d {
  &-block {
    display: block;
  }
  &-flex {
    display: flex;
  }
}

.align-items {
  &-start {
    align-items:flex-start; 
  }
  &-end {
    align-items: flex-end;
  }
  &-baseline {
    align-items: baseline
  }
  &-center {
    align-items: center
  }
}

.justify-content {
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-center {
    justify-content: center;
  }
}

.flex-direction {
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
}

.hide-sm {
  @include respond-to(sm) {
    display: none;
  }
}

.show-sm {
  display: none;
  @include respond-to(sm) {
    display: block;
  }
}

.hidden {
  display: none;
}
