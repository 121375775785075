$white: #ffffff;
$black: #1f1c17;

$gray-100: #f7f6f4;
$gray-200: #d7d7d6;
$gray-250: #eeebe5;
$gray-300: #c6bdaa;
$gray-350: #b3b2b1;
$gray-400: #888888;
$gray-600: #4c4c4c;
$gray-700: #3d3a36;
$gray-800: #333333;
$gray-900: #1f1c17;

$green-100: #f1f4ed;
$green-150: #e1e9d9;
$green-175: #d6e1ca;
$green-185: #d5dbce;
$green-200: #9eb781;
$green-300: #90ae6e;
$green-400: #8ca371;
$green-500: #7a8e62;
$green-600: #566e3a;

$red-100: #faeeee;
$red-500: #e54b4b;
